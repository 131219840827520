.footer {
  width: 100%;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: -100px;
  font-size: 1rem;

  a {
    text-transform: initial;
    border-bottom: none;
    font-weight: 400;
    font-size: 1rem;
  }
}
