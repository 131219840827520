#__bg {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  mix-blend-mode: screen;

  .overlay {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: rgb(0, 0, 0);
    background: radial-gradient(
      circle,
      rgba(0, 0, 0, 0) -70%,
      rgba(20, 23, 27, 0.8870098381149334) 72%
    );
  }
}
