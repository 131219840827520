* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

//FONTS

@import url("https://fonts.googleapis.com/css2?family=Playfair+Display&family=Roboto:wght@400;500;700&display=swap");

$font-primary: "Playfair Display", serif;
$font-secondary: "Roboto", sans-serif;

//COLORS

$bg-dark: #0d0f12;
$bg-border: #12171c;

$text-white: #fff;
$text-lighter: #f8f8f8;
$text-darker: #9e9e9e;
$text-darkest: #56565669;

// $text-lighter: #8f8f8f;
// $text-darker: #585858;

$accent: #36aec1;
$gray-1: #9d9ba3;
$gray-2: #8da8c0;
$gray-3: #5b6175;

//BREAKPOINTS

$mobile: 900px;
$tablet: 1200px;
$retina: 2000px;

//PADDING

$pd-lg: 200px;
$pd-xs: 50px;
