@import "../../base";

.site-intro {
  background: $bg-dark;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 5;
  transform: translate3d(0, 0, 0);
}

.intro-text {
  font-size: 1.3rem;
}

.slider {
  background: $bg-border;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transform: translateX(0%);
  z-index: 4;
}
