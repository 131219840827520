@import "../../base";

.navbar {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100px;
  position: sticky;
  top: 0;
  // background-color: $bg-dark;
  z-index: 4;

  &__inner {
    max-width: 1600px;
    margin: auto;
    width: 100%;
    padding: 0 5%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__logo {
    user-select: none;

    img {
      height: 20px;
    }

    &--insight {
      margin-left: 15px;
    }

    @media (max-width: $mobile) {
      img {
        height: 10px;
      }

      &--insight {
        margin-left: 10px;
      }
    }
  }

  &__links {
    display: flex;
    list-style-type: none;
    font-weight: 500;
    color: $text-white;
    text-transform: uppercase;
    letter-spacing: 2px;

    li {
      padding: 10px 20px;

      &.is-current a {
        color: $accent;
      }

      @media (max-width: $mobile) {
        display: none;

        &.mobile-visible {
          display: block !important;
          font-weight: 400;
          font-size: 0.8rem;
        }
      }
    }
  }
}
