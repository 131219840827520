@import "base";

html {
  font-size: 12px;
  scroll-behavior: smooth;
}

::selection {
  background-color: $accent;
  color: $text-white;
}

body {
  font-family: $font-secondary;
  background: $bg-dark;
  border: 25px solid $bg-border;
  min-height: 100vh;
  color: $text-darker;
  overflow-x: hidden;

  @media (max-width: $mobile) {
    border: 10px solid $bg-border;
  }
}

h1,
h2 {
  font-size: 45px;
  font-family: $font-primary;
  font-weight: 400;
  line-height: 60px;
  margin-bottom: 50px;

  span {
    color: $text-lighter;
  }

  @media (max-width: $mobile) {
    font-size: 25px;
    line-height: 45px;
    margin-bottom: 30px;
  }
}

// h2 {
//   font-size: 16px;
//   color: $accent;
// }

a {
  color: $text-white;
  text-decoration: none;
}

.main {
  font-size: 1.2rem;
  // overflow-x: hidden;

  p {
    line-height: 30px;
    font-size: 1.6rem;

    @media (max-width: $mobile) {
      line-height: 30px;
      font-size: 1.4rem;
    }
  }

  a {
    color: $accent;
    text-transform: uppercase;
    font-weight: 500;
    letter-spacing: 2px;
    font-size: 1.8rem;

    @media (max-width: $mobile) {
      line-height: 30px;
      font-size: 0.8rem;
    }
  }

  ul {
    line-height: 30px;
    font-size: 1.6rem;

    @media (max-width: $mobile) {
      line-height: 30px;
      font-size: 1.4rem;
    }
  }
}

.container {
  max-width: 1600px;
  padding: 0 5%;
  margin: auto;
  position: relative;
}
