@import "../../base";

.sectionNumber {
  width: 100%;
  height: 100px;
  position: relative;
  margin-top: 100px;

  &__number {
    position: absolute;
    right: -0px;
    top: -80px;
    z-index: 1;
    font-size: 115px;
    font-weight: 700;
    color: $text-darkest;
  }

  &__title {
    position: absolute;
    right: 25px;
    z-index: 2;
    color: $accent;
    text-transform: uppercase;
    font-weight: 400;
    letter-spacing: 2px;
    font-size: 1.8rem;
  }
}
